import cloneDeep from 'lodash.clonedeep';

export const OPTION_NOTHING = "-1";
export const GENERIC_ERROR_MESSAGE = 'An error occured while processing your request. Please try again or contact administrator.';
export const API_STATUS_OK = 1;
export const API_STATUS_FAIL = 0;

export const LABEL_MAPPING = {
  'parentAccount.firstName': 'Parent/Guardian First Name',
  'parentAccount.lastName': 'Parent/Guardian Last Name',
  'parentAccount.password': 'Parent/Guardian Password',
  'parentAccount.confirmPassword': 'Parent/Guardian Password (Confirm)',
  'parentAccount.emailAddress': 'Parent/Guardian Email Address/User Name',
  'parentAccount.confirmEmailAddress': 'Parent/Guardian Email Address/User Name (Confirm)',
  'parentAccount.existingAccount': 'I already have a Parent/Guardian Account',
  'studentAccount.firstName': 'First Name',
  'studentAccount.lastName': 'Last Name',
  'studentAccount.userName': 'User Name',
  'studentAccount.confirmUserName': 'User Name (Confirm)',
  'studentAccount.password': 'Password',
  'studentAccount.confirmPassword': 'Password (Confirm)',
  'studentAccount.grade': 'Grade',
  'studentAccount.language': 'Language',
  'parentAccount.email_weekly': 'I want to receive weekly emails about my student’s progress in Smarty Ants.'
}

export const NOT_REQUIRED_FIELDS = [
  'parentAccount.email_weekly'
]

export const INITIAL_NEW_STUDENT = {
  firstName: '',
  lastName: '',
  userName: '',
  confirmUserName: '',
  password: '',
  confirmPassword: '',
  grade: OPTION_NOTHING,
  language: OPTION_NOTHING,
}

export const INITIAL_NEW_ACCOUNT = {
  parentAccount: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    confirmEmailAddress: '',
    password: '',
    confirmPassword: '',
    email_weekly: true,
  },
  studentAccounts: [
    INITIAL_NEW_STUDENT
  ]
}

export const MAX_INPUT_CHAR = 75;

export const OPTIONS_GRADE = [
  {
    value: OPTION_NOTHING,
    label: 'SELECT GRADE'
  },
  {
    value: "1",
    label: 'Pre-K'
  }
];

export const OPTIONS_LANG = [
  {
    value: OPTION_NOTHING,
    label: 'SELECT LANGUAGE'
  },
  {
    value: "1",
    label: 'English'
  },
  {
    value: "2",
    label: 'English with Spanish help'
  }
]

export function sanitizeConfirmationPayload(values) {
  const payload = cloneDeep(values);
  delete payload.parentAccount.password;
  delete payload.parentAccount.confirmPassword;
  if (payload.studentAccounts) {
    payload.studentAccounts.map(function(student) {
      delete student.password;
      delete student.confirmPassword;
      return student;
    });
  }
  return payload;
}
