import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { GENERIC_ERROR_MESSAGE, API_STATUS_FAIL } from '../account/Types';
import { BASE_URL } from './types';

const axiosInstance = axios.create({
  withCredentials: true
});

export function sanitizeSignupPayload(values) {
  const payload = cloneDeep(values);
  delete payload.parentAccount.confirmPassword;
  if (payload.studentAccounts) {
    payload.studentAccounts.map(function(student) {
      delete student.confirmPassword;
      return student;
    });
  }
  return payload;
}

export const sample_success = {
  parentAccount: {
    firstName: 'Daniel',
    lastName: 'Bibal',
    emailAddress: 'hello@test.com',
  },
  studentAccounts: [
    {
      firstName: 'John',
      lastName: 'Smith',
      userName: 'jsmith',
    },
    {
      firstName: 'Bob',
      lastName: 'Smith',
      userName: 'bsmith',
    },
    {
      firstName: 'Mark',
      lastName: 'Smith',
      userName: 'msmith',
    },
    {
      firstName: 'Jane',
      lastName: 'Smith',
      userName: 'j2smith',
    }
  ]
}

export const sample_error = {
  success: API_STATUS_FAIL,
  errors: {
    parentAccount: {
      firstName: 'First Name has an error',
      emailAddress: 'Email is already in use',
    },
    studentAccounts: [
      {
        firstName: 'Student 1 error goes here',
        userName: 'username already in use',
      },
      {},
      {
        lastName: 'Last name not cool',
        userName: 'invalid username',
      }
    ],
    common: 'This is a generic error'
  }
}

function generateErrorObject(message, err) {
  return {
    status: 0,
    errors: {
      common: message,
      appError: err,
    }
  }
}
export function signup(payload) {
  return new Promise(function(resolve, reject) {

    // setTimeout(() => {
    //   resolve(sample_error);
    // }, 500);

    try {
      axiosInstance.post(`${BASE_URL}createhomeuser`, payload)
      .then(function(response) {
        resolve(response.data);
      }).catch(function(err) {
        reject(generateErrorObject(GENERIC_ERROR_MESSAGE, err));
      });
    } catch(err) {
      reject(generateErrorObject(GENERIC_ERROR_MESSAGE, err));
    }
  });
}