import React, { Component } from 'react';

class ErrorMessage extends Component {
  
  render() {
    const { status = {} } = this.props;
    const commonError = status ? (status.common || '') : '';
    if (!commonError) {
      return null;
    }

    return (
      <div className="message is-light is-danger">
        <div className="message-body">
          {commonError}
        </div>
      </div>
    );
  }
}

export default ErrorMessage;