import React, { Component } from 'react';
import { Formik, setIn } from 'formik';
import validation from './Validation';
import ErrorMessage from '../form/ErrorMessage';
import { INITIAL_NEW_ACCOUNT, API_STATUS_FAIL, API_STATUS_OK, sanitizeConfirmationPayload } from './Types';
import AccountSignupParentSection from './SignupParentSection';
import AccountSignupBottomSection from './SignupBottomSection';
import StudentLimitWarning from '../form/StudentLimitWarning';
import SignupVerifyModal from './SignupVerifyModal';
import { signup, sanitizeSignupPayload } from '../service/account';

export const setFieldLabel = (label, required = true) => {
  return (
    <span>
      {label}
      {required && <span className="has-text-danger"> *</span>}
    </span>
  )
}
class AccountSignup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    }
  }
  

  render() {
    
    const setModalState = (value) => {
      this.setState({ showModal: value });
    }
    
    const updateSignupInfo = (signupInfo) => {
      this.props.setSignupInfo(signupInfo);
    }

    return (
      <div className="box">
        <Formik
          initialValues={{
            districtId: this.props.districtId,
            ...INITIAL_NEW_ACCOUNT
          }}
          validate={validation}
          onSubmit={function() {
            setModalState(true);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setSubmitting,
            setFieldTouched,
            status,
            setStatus,
          }) => {
            function handleModalCancel() {
              setModalState(false);
              setSubmitting(false);
            }
           
            function clearStatus(property) {
              const newStatus = setIn(status, property, undefined);
              setStatus(newStatus);
            }
            
            function handleModalOk() {
              setModalState(false);
              const payload = sanitizeSignupPayload(values);
              
              signup(payload).then(function(response) {
                if (response.success === undefined || response.success === API_STATUS_FAIL) {
                  setStatus(response.errors);
                  setSubmitting(false);
                } else if (response.success === API_STATUS_OK) {
                  const signupInfo = sanitizeConfirmationPayload(payload);
                  signupInfo.studentAccounts = response.studentAccounts;
                  updateSignupInfo(signupInfo);
                } else {
                  setSubmitting(false);
                }
              }).catch(function(err) {
                setStatus(err);
                setSubmitting(false);
              });
            }
            return (
              <>
                <SignupVerifyModal
                  showModal={this.state.showModal}
                  handleModalCancel={handleModalCancel}
                  handleModalOk={handleModalOk}
                  values={values}
                />
                <form id="create_account" onSubmit={handleSubmit}>
                  <h1 className="title is-2 has-text-centered has-text-weight-bold">Sign Up Here</h1>
                  <div className="bd-snippet-preview">
                    <ErrorMessage status={status} />
                    {
                      values.studentAccounts.length >= 4 && (
                        <StudentLimitWarning />
                      )
                    }
                    <AccountSignupParentSection
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      status={status}
                      clearStatus={clearStatus}
                    />                 
                    <AccountSignupBottomSection
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isSubmitting={isSubmitting}
                      setFieldTouched={setFieldTouched}
                      status={status}
                      clearStatus={clearStatus}
                    />
                  </div>
                </form>
                </>
              )
          }}
        </Formik>
      </div>
    );
  }
};

export default AccountSignup;