import axios from 'axios';
import { BASE_URL } from './types';

const axiosInstance = axios.create({
  withCredentials: true
});

export function getDistrict(districtId) {
  return new Promise(function(resolve, reject) {
    // setTimeout(() => {
    //   resolve(true);
    // }, 6000);
  
    try {
      axiosInstance.get(`${BASE_URL}crisis/district/${districtId}`)
      .then(function(response) {
        resolve(response.data);
      }).catch(function(err) {
        reject(err);
      });
    } catch(err) {
      reject({ error: err });
    }
  });
}