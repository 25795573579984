import React, { Component } from 'react';
import clsx from 'clsx';

class Modal extends Component {

  render() {
    return (
      <div className={
        clsx(
          "modal",
          this.props.isActive && "is-active"
        )
      }>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{this.props.title}</p>
          </header>
          <div className="modal-card-body">
            {this.props.children}
          </div>
          <footer className="modal-card-foot">
            <button
              onClick={this.props.handleOk}
              className="button is-success"
            >
              {this.props.okLabel}
            </button>
            <button
              onClick={this.props.handleCancel}
              className="button is-danger"
            >
              {this.props.cancelLabel}
            </button>
          </footer>
        </div>
      </div>
    )
  }
}

export default Modal;