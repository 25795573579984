import React, { Component } from 'react';

import { getIn } from 'formik';
import Input from '../form/Input';
import Select from '../form/Select';
import { LABEL_MAPPING, OPTIONS_LANG, OPTIONS_GRADE } from './Types';
import { setFieldLabel } from './Signup';

class SignupStudentSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
    }
  }

  render() {
    const { index, touched, errors, status, clearStatus, student, handleChange, handleBlur, setFieldTouched } = this.props;
    const count = index + 1;

    const passwordInputType = this.state.isPasswordVisible ? 'text' : 'password';
    const passwordInputIcon = this.state.isPasswordVisible ? 'fas fa-eye' : 'fas fa-eye-slash';
    const handlePasswordIconClick = () => {
      this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
    }

    const confirmPasswordInputType = this.state.isConfirmPasswordVisible ? 'text' : 'password';
    const confirmPasswordInputIcon = this.state.isConfirmPasswordVisible ? 'fas fa-eye' : 'fas fa-eye-slash';
    const handleConfirmPasswordIconClick = () => {
      this.setState({ isConfirmPasswordVisible: !this.state.isConfirmPasswordVisible });
    }

    return (
      <div  key={`student-form-${index}`} className="panel is-info is-shadowless signup-student-panel">
        <p className="panel-heading">{`Student ${count}`}</p>

        <div className="columns is-desktop">
          <div className="column is-half">
            <Input
              label={setFieldLabel(`Student ${count} ${LABEL_MAPPING['studentAccount.firstName']}`)}
              touched={getIn(touched, 'firstName')}
              error={getIn(errors, 'firstName')}
              status={getIn(status, 'firstName')}
              id={`studentAccounts[${index}].firstName`}
              name={`studentAccounts[${index}].firstName`}
              value={student.firstName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              clearStatus={clearStatus}
            />
          </div>
          <div className="column is-half">
            <Input
              label={setFieldLabel(`Student ${count} ${LABEL_MAPPING['studentAccount.lastName']}`)}
              touched={getIn(touched, 'lastName')}
              error={getIn(errors, 'lastName')}
              status={getIn(status, 'lastName')}
              id={`studentAccounts[${index}].lastName`}
              name={`studentAccounts[${index}].lastName`}
              value={student.lastName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              clearStatus={clearStatus}
            />
          </div>
        </div>
        
        <div className="columns is-desktop">
          <div className="column is-half">
            <Input
              label={setFieldLabel(`Student ${count} ${LABEL_MAPPING['studentAccount.userName']}`)}
              touched={getIn(touched, 'userName')}
              error={getIn(errors, 'userName')}
              status={getIn(status, 'userName')}
              id={`studentAccounts[${index}].userName`}
              name={`studentAccounts[${index}].userName`}
              value={student.userName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              clearStatus={clearStatus}
            />
            <Input
              label={setFieldLabel(`Student ${count} ${LABEL_MAPPING['studentAccount.confirmUserName']}`)}
              touched={getIn(touched, 'confirmUserName')}
              error={getIn(errors, 'confirmUserName')}
              status={getIn(status, 'confirmUserName')}
              id={`studentAccounts[${index}].confirmUserName`}
              name={`studentAccounts[${index}].confirmUserName`}
              value={student.confirmUserName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              clearStatus={clearStatus}
            />
          </div>
          <div className="column is-half">
            <Input
              label={setFieldLabel(`Student ${count} ${LABEL_MAPPING['studentAccount.password']}`)}
              touched={getIn(touched, 'password')}
              error={getIn(errors, 'password')}
              status={getIn(status, 'password')}
              id={`studentAccounts[${index}].password`}
              name={`studentAccounts[${index}].password`}
              value={student.password}
              handleChange={handleChange}
              handleBlur={handleBlur}
              rightIcon={passwordInputIcon}
              onRightIconClick={handlePasswordIconClick}
              type={passwordInputType}
              clearStatus={clearStatus}
              maxLength={49}
            />
            <Input
              label={setFieldLabel(`Student ${count} ${LABEL_MAPPING['studentAccount.confirmPassword']}`)}
              touched={getIn(touched, 'confirmPassword')}
              error={getIn(errors, 'confirmPassword')}
              status={getIn(status, 'confirmPassword')}
              id={`studentAccounts[${index}].confirmPassword`}
              name={`studentAccounts[${index}].confirmPassword`}
              value={student.confirmPassword}
              handleChange={handleChange}
              handleBlur={handleBlur}
              rightIcon={confirmPasswordInputIcon}
              onRightIconClick={handleConfirmPasswordIconClick}
              type={confirmPasswordInputType}
              clearStatus={clearStatus}
              maxLength={49}
            />
          </div>
        </div>
        <div className="columns is-desktop">
          <div className="column is-half">            
            <Select
              label={setFieldLabel(`Student ${count} ${LABEL_MAPPING['studentAccount.language']}`)}
              id={`studentAccounts[${index}].language`}
              name={`studentAccounts[${index}].language`}
              touched={getIn(touched, 'language')}
              error={getIn(errors, 'language')}
              status={getIn(status, 'language')}
              value={student.language}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
              options={OPTIONS_LANG}
              clearStatus={clearStatus}
            />
          </div>
          <div className="column is-half">
            <Select
              label={setFieldLabel(`Student ${count} ${LABEL_MAPPING['studentAccount.grade']}`)}
              id={`studentAccounts[${index}].grade`}
              name={`studentAccounts[${index}].grade`}
              touched={getIn(touched, 'grade')}
              error={getIn(errors, 'grade')}
              status={getIn(status, 'grade')}
              value={student.grade}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
              options={OPTIONS_GRADE}
              clearStatus={clearStatus}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default SignupStudentSection;