import React, { Component } from 'react';
import Modal from '../form/Modal';

class SignupVerifyModal extends Component {

  render() {
    const { showModal, handleModalCancel, handleModalOk, values } = this.props;
    return(
      <Modal
        isActive={showModal}
        title="Create Accounts"
        handleCancel={handleModalCancel}
        handleOk={handleModalOk}
        okLabel="Submit"
        cancelLabel="Return to Form"
      >
        <p className="is-primary">Here are the passwords for the accounts you are creating.  Please make sure you keep them safe for use on our sites.</p>
        <ul>
          <li>
            <p className="has-text-weight-bold is-marginless">Parent/Guardian</p>
            <div><strong>Username: </strong>{values.parentAccount.emailAddress}</div>
            <div><strong>Password: </strong>{values.parentAccount.password}</div>
          </li>
        </ul>
        <ul>
          {
            values.studentAccounts.map(function(student, index) {
              return (
                <li key={`student-${index}`}>
                  <p className="has-text-weight-bold is-marginless">{`Student ${index + 1}`}</p>
                  <div><strong>Username: </strong>{student.userName}</div>
                  <div><strong>Password: </strong>{student.password}</div>
                  <br/>
                </li>
              )
            })
          }
        </ul>
        <p>Have you created all the student accounts you need? If not, please return to the form and use the “Add Another Student” button to add additional student users.</p>
      </Modal>
    ) 
  }
}

export default SignupVerifyModal;