import React, { Component } from 'react';


class InvalidUrlMessage extends Component {

  render() {
    return(
      <div className="box has-text-centered">
        <h1 className="subtitle is-4 has-text-weight-bold has-text-info">We're sorry, but that page does not exist</h1>
        <h1 className="subtitle is-6">
         To sign up for Smarty Ants at Home, please use the link that you received from your school, district, or from Achieve3000.  If you feel you are getting this message in error, please <a className="has-text-info has-text-weight-bold" href="https://www.achieve3000.com/about/contact-us/" target="_blank" rel="noopener noreferrer">contact us</a>.
        </h1>
      </div>
    )
  }
}

export default InvalidUrlMessage;