import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";

import qs from 'qs';
import AccountSignup from "./account/Signup";
import DisabledUrlMessage from "./DisabledUrlMessage";
import InvalidUrlMessage from "./InvalidUrlMessage";
import SignupConfirmation from './account/SignupConfirmation';

import "./App.css";
// import { sample_success } from './service/account';
import { getDistrict } from './service/district';
import Loader from './Loader';

const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
const { district_id } = searchParams;

function App() {
  const [district, setDistrict] = React.useState(null);
  const [isLoading, setLoading] = React.useState(true);
  const [signupInfo, setSignupInfo] = React.useState(null);

  React.useEffect(() => {
    // bypass distict check
    // setDistrict(true);
    // setLoading(false);

    if (district_id) {
      getDistrict(district_id).then(function(districtInfo) {
        document.getElementById('district_name').innerText = districtInfo.district_name;
        document.getElementById('district_name_bold').innerText = districtInfo.district_name;
        document.getElementById('exp_date').innerText = districtInfo.district_exp_dt;
        setDistrict(districtInfo);
      }).catch(function() {
        setDistrict(null);
      }).finally(function(){
        setLoading(false);
      })
    } else {
      setLoading(false);
    }
  }, []);

  const signupComponent = signupInfo ? <SignupConfirmation signupInfo={signupInfo} setSignupInfo={setSignupInfo} /> : <AccountSignup setSignupInfo={setSignupInfo} districtId={district_id} />;
  const renderComponent = isLoading ? <Loader /> : district ? signupComponent : DefaultPage();

  return (
    <div className="App">
      {renderComponent}
    </div>
  )
}

function DefaultPage() {
  if (district_id && district_id === "disabled") {
    return <DisabledUrlMessage />;
  }

  return <InvalidUrlMessage />;
}

export default App;
