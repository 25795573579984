import { OPTION_NOTHING, LABEL_MAPPING, NOT_REQUIRED_FIELDS } from "./Types";

function validation(values) {
  const errors = {
    parentAccount: {},
    studentAccounts: {},
    errors: []
  };
  const tempStudentNames = [];

  // Check All Parent Account fields are not empty
  Object.keys(values.parentAccount).map(function(key) {
    const field = `parentAccount.${key}`
    if (!values.parentAccount[key] && !NOT_REQUIRED_FIELDS.includes(field)) {
      errors.parentAccount[key] = `${LABEL_MAPPING[`parentAccount.${key}`]} is required.`;
    }
    return null;
  });

  // Parent passwords match
  if ((values.parentAccount.password && values.parentAccount.confirmPassword) && (values.parentAccount.password !== values.parentAccount.confirmPassword)) {
    errors.parentAccount.password = 'The parent passwords do not match. Please re-enter.';
    errors.parentAccount.confirmPassword = 'The parent passwords do not match. Please re-enter.';
  }

  // Parent email correct format
  if (values.parentAccount.emailAddress && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.parentAccount.emailAddress))) {
    errors.parentAccount.emailAddress = 'Email address is not formatted correctly, please update.';
  }
  // Confirm parent email correct format
  if (values.parentAccount.confirmEmailAddress && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.parentAccount.confirmEmailAddress))) {
    errors.parentAccount.confirmEmailAddress = 'Email address is not formatted correctly, please update.';
  }

  // Parent email address match
  if ((values.parentAccount.emailAddress && values.parentAccount.confirmEmailAddress) && (values.parentAccount.emailAddress !== values.parentAccount.confirmEmailAddress)) {
    errors.parentAccount.emailAddress = 'The parent email address do not match. Please re-enter.';
    errors.parentAccount.confirmEmailAddress = 'The parent email address do not match. Please re-enter.';
  }

  // Check Students fields are not empty
  values.studentAccounts.map(function(student, index) {
    const studentError = {};
    Object.keys(student).map(function(key) {
      
      // Required fields
      if (!student[key] || student[key] === OPTION_NOTHING) {
        studentError[key] = `Student ${index + 1} ${LABEL_MAPPING[`studentAccount.${key}`]} is required.`;
      }
      return null;
    });

    if ((student.userName && student.confirmUserName) && (student.userName === student.confirmUserName)) {
      if (tempStudentNames.find(function(name) {
        return (String(name) === String(student.userName));
      })) {
        studentError.userName = `The student ${index + 1} user name is already added in the previous row. Please re-enter.`;
      } else {
        tempStudentNames.push(String(student.userName));
      }
    }
   
    // Password mismatch
    if((student.password && student.confirmPassword) && (student.password !== student.confirmPassword)) {
      studentError.password = `The student ${index + 1} passwords do not match. Please re-enter.`;
      studentError.confirmPassword = `The student ${index + 1} passwords do not match. Please re-enter.`;
    }

    // Password mismatch
    if((student.password && student.confirmPassword) && (student.password !== student.confirmPassword)) {
      studentError.password = `The student ${index + 1} passwords do not match. Please re-enter.`;
      studentError.confirmPassword = `The student ${index + 1} passwords do not match. Please re-enter.`;
    }

    // username mismatch
    if((student.userName && student.confirmUserName) && (student.userName !== student.confirmUserName)) {
      studentError.userName = `The student ${index + 1} user name do not match. Please re-enter.`;
      studentError.confirmUserName = `The student ${index + 1} user name do not match. Please re-enter.`;
    }

    if (Object.keys(studentError).length > 0) {
      errors.studentAccounts[index] = studentError;
    };
    return null;
  });

  // clean up if no errors found
  if (Object.keys(errors.parentAccount).length === 0) {
    delete errors.parentAccount;
  }
  if (Object.keys(errors.studentAccounts).length === 0) {
    delete errors.studentAccounts;
  }

  if (errors.errors.length === 0) {
    delete errors.errors;
  }
  return errors;
}

export default validation;
