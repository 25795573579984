import React, { Component } from 'react';
import clsx from 'clsx';

class Select extends Component {
  render() {

    const setTouched = () => {
      this.props.setFieldTouched(this.props.id, true);
    }
    return (
      <div className="field">
        <label className="label">{this.props.label}</label>
        <div
          className={clsx(
            "select",
            (this.props.touched && this.props.error) && "is-danger",
            "is-fullwidth"
          )}
        >
          <select
            id={this.props.id}
            name={this.props.name}
            value={this.props.value}
            onChange={this.props.handleChange}
            onBlur={setTouched}
          >
            {
              this.props.options.map(function(option, index) {
                return (
                  <option key={`${Math.random()}-option-${index}`} value={option.value}>
                    {option.label}
                  </option>
                )
              })
            }
          </select>          
        </div>
        {this.props.touched && this.props.error && (
          <p className="help is-danger is-marginless">{this.props.error}</p>
        )}
        {this.props.touched && this.props.status && (
          <p className="help is-danger is-marginless">{this.props.status}</p>
        )}
      </div>
    )
  }
}

export default Select;