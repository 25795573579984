import React, { Component } from 'react';
import clsx from 'clsx';
import { MAX_INPUT_CHAR } from '../account/Types';

class Input extends Component {

  render() {
    const isCheckBoxOrRadio = this.props.type ===  "checkbox" || this.props.type ===  "radio"
    const handleChange = (e) => {
      if (this.props.status && this.props.clearStatus) {
        this.props.clearStatus(this.props.id);
      }
      this.props.handleChange(e);
    }
    
    return (
      <div className="field">
        {!isCheckBoxOrRadio && <label className="label">{this.props.label}</label>}
        <div
          className={
            clsx(
              "control",
              this.props.rightIcon && "has-icons-right"
            )
          }>
          <input
            className={clsx(
              this.props.type ===  "checkbox" && "checkboxInput",
              this.props.type ===  "radio" && "radioInput",
              !isCheckBoxOrRadio && "input",
              (this.props.touched && (this.props.error || this.props.status)) && "is-danger"
            )}
            id={this.props.id}
            name={this.props.name}
            type={this.props.type || "text"}
            defaultChecked={this.props.defaultChecked}
            value={this.props.value}
            onChange={handleChange}
            onBlur={this.props.handleBlur}
            maxLength={this.props.maxLength || MAX_INPUT_CHAR}
          />
          {isCheckBoxOrRadio && <label style={{ 'display': 'inline-block', 'padding': '5px'}} className="label">{this.props.label}</label>}
          { this.props.rightIcon && 
            (
              <span
                key={this.props.rightIcon} // this makes the icon refresh on change of state https://stackoverflow.com/questions/49899245/setstate-not-updating-font-awesome-icon
                className="icon is-small is-right"
                onClick={this.props.onRightIconClick}
                style={{
                  ...this.props.onRightIconClick && { cursor: 'pointer', pointerEvents: 'initial' }
                }}
              >
                <i className={this.props.rightIcon}></i>
              </span>
            )
          }
        </div>
        {this.props.touched && this.props.error && (
          <p className="help is-danger is-marginless">{this.props.error}</p>
        )}
        {this.props.touched && this.props.status && (
          <p className="help is-danger is-marginless">{this.props.status}</p>
        )}
      </div>
    )
  }
}

export default Input;