import React, { Component } from 'react';


class DisabledUrlMessage extends Component {

  render() {
    return(
      <div className="box has-text-centered">
        <h1 className="subtitle is-4 has-text-weight-bold has-text-info">Access to Smarty Ants at Home will be ending on July 30th, 2020</h1>
        <h1 className="subtitle is-6">
            We hope your child has enjoyed learning to read with us.<br />Please contact your school/district to find out about getting Smarty Ants at school in the fall.
        </h1>
      </div>
    )
  }
}

export default DisabledUrlMessage;