import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import './Signup.css';

class EmptyComponent extends React.Component {
  render() {
    return (null);
  }
}

class SignupConfirmation extends Component {
  // beforeunload = (e) => {
  //   if (true) {
  //     e.preventDefault();
  //     alert('!!!');
  //     e.returnValue = 'WHYYYY';
  //   }
  // };

 componentDidMount() {
    // window.addEventListener("beforeunload", this.beforeunload);
    window.scrollTo(0, 0);
 }

//  componentWillUnmount() {
//     window.removeEventListener("beforeunload", this.beforeunload);
//  }

  render() {
    const { parentAccount, studentAccounts } = this.props.signupInfo || {};

    // function handlePrint() {
    //   window.print();
    // }

    const renderStudentAccount = (student, index) => {
      if (!student) {
        return (<td></td>);
      }
      return (
        <tr key={`row-${index}`}>
            <td className="has-text-weight-normal">{student.firstName}</td>
            <td className="has-text-weight-normal">{student.lastName}</td>
            <td className="has-text-weight-normal">{student.userName}</td>
            <td className="has-text-weight-normal">{student.expDate}</td>
        </tr>
      )  
    }

    const ele = new EmptyComponent({});
    const componentRef = React.createRef(ele);

    return(
      <div className="box" ref={componentRef}>
        <div className="title is-4 has-text-weight-bold has-text-info has-text-centered">Congratulations, you have registered for Smarty Ants Community Grant!</div>
        <div className="message is-light is-warning screen-only">
          <div
            className="message-body"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <strong>
                IMPORTANT:&nbsp;
              </strong>
              Please print or save this page to keep track of account information for you and your student.
            </div>
            <ReactToPrint
              trigger={() => <button className="button is-info" style={{ marginLeft: '15px'}}>Print</button>}
              content={() => componentRef.current === null ? ele : componentRef.current}
              pageStyle="@page { size: A4; margin: 10mm; height: 100%; }"
            />
          </div>
        </div>
        <div className="columns">
          <div className="column" style={{ paddingTop: 0, paddingBottom: 0}}>
            <div className="image is-96x96">
              <img className="image-ant" alt="ant" src="girl-ant.png" />
            </div>
          </div>
          <div className="column is-10">
            <p className="is-marginless">Children do not need to stop learning just because school is not in session! We recommend students complete at least three 20-minute Smarty Ants sessions a week to become successful and independent readers.</p>
          </div>
        </div>
        
        <div className="columns">
          <div className="column is-10">
            <p className="is-marginless">Smarty Ants works best on a computer browser or an Apple/Android/Amazon tablet.<strong> Smarty Ants has not been optimized for use on mobile phones.</strong> Follow the directions below so that your child can continue to learn to read while at home. Want to know if your system or device will work with Smarty Ants? Please refer to our <a className="has-text-info" href="https://www.achieve3000.com/system-requirements/" target="_blank" rel="noopener noreferrer">System Requirements</a> for details.</p>
          </div>
          <div className="column" style={{ paddingTop: 0, paddingBottom: 0}}>
            <div className="image is-96x96">
              <img className="image-ant" alt="ant" src="computer-ant.png" />
            </div>
          </div>
        </div>
        {/* <div className="message is-light is-info" style={{ marginTop: '1.5rem'}}>
          <div className="message-body">
            <p className="is-marginless">If you forget your student’s password or need support of any kind, contact us at <strong>support@achieve3000.com</strong> or call <strong>877-235-2525</strong> Mon-Fri, 7:30am to 9:00pm EST.  Be prepared to provide your parent account information to verify your identity.</p>
          </div>
        </div> */}
        <h1 className="title is-5 has-text-weight-bold">Information for Students</h1>
        <div className="signup-confirmation-content">
          <p className="is-marginless"><strong>Students</strong> can start learning with Smarty Ants right away! Log in to <a className="has-text-info" href="https://play.smartyants.com/login" target="_blank" rel="noopener noreferrer">Smarty Ants</a> using the student username below and the password you created.</p>
        </div>
        <div className="message is-light is-info" style={{ marginTop: '1.5rem'}}>
          <div className="message-body">
            <p className="is-marginless">Please remember to bookmark both the parent and student login pages the first time you visit the sites, so you can easily return to them.</p>
          </div>
        </div>
        <table style={{ marginTop: '1.5rem'}} className="table is-narrow is-bordered is-fullwidth has-text-weight-bold has-text-grey-dark">
          <thead>
            <tr className="has-background-light is-light">
              <td className="has-text-info">First Name</td>
              <td className="has-text-info">Last Name</td>
              <td className="has-text-info">User Name</td>
              <td className="has-text-info">Expiration Date</td>
            </tr>
          </thead>
          <tbody>
            {
              studentAccounts.map(function(student, index) {
                return renderStudentAccount(student, index)
              })
            }
          </tbody>
        </table>
        <p className="has-text-weight-bold">Student Login page: <a className="has-text-info" href="https://play.smartyants.com/login" target="_blank" rel="noopener noreferrer">https://play.smartyants.com/login</a></p>
        
        <div className="screen-only">
          <p>OR</p>
          <p>Try Smarty Ants Mobile applications on your tablet: </p>
          <h1 className="title is-6 has-text-weight-bold">Smarty Ants PreK-1</h1>
          <div className="columns">
            <div className="column">
              <a href="https://itunes.apple.com/us/app/smarty-ants-prek-1/id533105568?mt=8" target="_blank" rel="noopener noreferrer">
                <img className="signup-confirmation-download-badge" alt="download in app store" src="download-app-store.svg" />
              </a>
            </div>
            <div className="column">
              <a href="https://play.google.com/store/apps/details?id=com.smartyants.app1" target="_blank" rel="noopener noreferrer">
                <img className="signup-confirmation-download-badge" alt="download in app store" src="download-google.png" />
              </a>
              
            </div>
            <div className="column">
              <a href="https://www.amazon.com/Smarty-Ants-PreK-1st-Grade/dp/B00NV4AYYY?ie=UTF8&keywords=smarty%20ants&qid=1413237422&ref_=sr_1_1&sr=8-1" target="_blank" rel="noopener noreferrer">
                <img className="signup-confirmation-download-badge " alt="download in app store" src="download-amazon.png" />
              </a>
            </div>
          </div>

          <h1 className="title is-6 has-text-weight-bold">Smarty Ants 2nd Grade</h1>
          <div className="columns">
            <div className="column">
              <a href="https://itunes.apple.com/us/app/smarty-ants-2nd-grade/id732970367?mt=8" target="_blank" rel="noopener noreferrer">
                <img className="signup-confirmation-download-badge" alt="download in app store" src="download-app-store.svg" />
              </a>
            </div>
            <div className="column">
              <a href="https://play.google.com/store/apps/details?id=com.smartyants.app2" target="_blank" rel="noopener noreferrer">
                <img className="signup-confirmation-download-badge" alt="download in app store" src="download-google.png" />
              </a>
              
            </div>
            <div className="column">
              <a href="https://www.amazon.com/Achieve3000-Inc-Smarty-Ants-Grade/dp/B00NWSP3NG?ie=UTF8&keywords=smarty%20ants&qid=1413237422&ref_=sr_1_3&sr=8-3" target="_blank" rel="noopener noreferrer">
                <img className="signup-confirmation-download-badge " alt="download in app store" src="download-amazon.png" />
              </a>
            </div>
          </div>
        </div>
        <div className="message is-light is-info" style={{ marginTop: '1.5rem'}}>
          <div className="message-body">
            <p className="is-marginless">If you need immediate assistance please visit our <strong><a href="https://helpcenter.achieve3000.com/hc/en-us/categories/1500000770561-Smarty-Ants" style={{ textDecoration: 'none'}}>Help Center</a></strong> to find FAQs, how-to articles, and videos that will help you become a Smart Ants expert. If you would like to speak with a support team member, please call us at <strong>877.235.2525</strong>. We're here from 7:30AM-9PM EST.</p>
          </div>
        </div>
        <h1 className="title is-5 has-text-weight-bold">Information for Parents/Guardians</h1>
        <div className="signup-confirmation-section">
          <p style={{ marginBottom: '15px', display: 'block' }}>
            <strong>Parents/Guardians</strong> can check on students progress on our <a className="has-text-info" href="https://dashboard.smartyants.com/loginhome" target="_blank" rel="noopener noreferrer">Parent Dashboard</a>, just log in with the username and password you created for the parent account.
          </p>
          <p className="has-text-weight-bold is-marginless">{`Parents/Guardians Username: ${parentAccount.emailAddress}`}</p>
          <p className="has-text-weight-bold">{`Parents/Guardians Login page: `}<a className="has-text-info" href="https://dashboard.smartyants.com/loginhome" target="_blank" rel="noopener noreferrer">https://dashboard.smartyants.com/loginhome</a></p>
        </div>
        <small style={{ display: 'block', marginBottom: '15px'}} className="is-italic">Forgot your parent password? The <strong>Parents/Guardians</strong> account password can be reset using “Reset your password” on the Parent Dashboard login screen</small>
        {/* <div className="message is-light is-info">
          <div className="message-body">
            <p className="is-marginless">Forgot your parent password? The <strong>Parent/Guardian</strong> account password can be reset using “Reset your password” on the Parent Dashboard login screen</p>
          </div>
        </div> */}
        <div className="message is-light is-info" style={{ pageBreakAfter: 'never'}}>
          <div className="message-body">
          <p>We look forward to helping your child continue to build their early reading skills before they start school. If you need immediate assistance please visit our <strong><a href="https://helpcenter.achieve3000.com/hc/en-us/categories/1500000770561-Smarty-Ants" style={{ textDecoration: 'none'}}>Help Center</a></strong> to find FAQs, how-to articles, and videos that will help you become a Smart Ants expert. If you would like to speak with a support team member, please call us at <strong>877.235.2525</strong>. We're here from 7:30AM-9PM EST. Have your parent account information ready to verify your identity.</p>
          </div>
        </div>

        <div className="message is-light is-warning screen-only">
          <div
            className="message-body"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <strong>
                IMPORTANT:&nbsp;
              </strong>
              Please print or save this page to keep track of account information for you and your student.
            </div>
            <ReactToPrint
              trigger={() => <button className="button is-info" style={{ marginLeft: '15px'}}>Print</button>}
              content={() => componentRef.current === null ? ele : componentRef.current}
              pageStyle="@page { size: A4; margin: 10mm; height: 100%; }"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default SignupConfirmation;