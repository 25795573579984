import React, { Component } from 'react';


class Loader extends Component {

  render() {
    return(
      <div className="box has-text-centered">
        <div className="button is-large is-loading" style={{ border: 'none', height: '48px', width: '48px'}}></div>
      </div>
    )
  }
}

export default Loader;