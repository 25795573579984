import React, { Component } from 'react';
import { FieldArray } from 'formik';
import { INITIAL_NEW_STUDENT } from './Types';
import SignupStudentSection from './SignupStudentSection';
import './Signup.css';

class AccountSignupBottomSection extends Component {

  render() {
    const { values, touched, errors, status = {}, clearStatus, handleChange, handleBlur, isSubmitting } = this.props;
    const setFieldTouched = this.props.setFieldTouched;
    return (
      <FieldArray
        name="studentAccounts"
        render={function(arrayHelpers) {
          function addAnotherStudent() {
            arrayHelpers.push(INITIAL_NEW_STUDENT);
          }
          function removeExtraStudent() {
            arrayHelpers.pop();
          }
          
          return (
            <>
              {
                values.studentAccounts.map(function(student, index) {
                  const touchedStudentAccount = touched.studentAccounts ? touched.studentAccounts[index] : {};
                  const errorsStudentAccount = errors.studentAccounts ? errors.studentAccounts[index] : {};
                  const statusStudentAccount = status.studentAccounts ? status.studentAccounts[index] : {};
                  
                  return (
                    <SignupStudentSection
                      key={`student-section-${index}`}
                      index={index}
                      touched={touchedStudentAccount}
                      errors={errorsStudentAccount}
                      status={statusStudentAccount}
                      clearStatus={clearStatus}
                      student={student}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  )
                })
              }
              <input type="hidden" name="district_id" id="district_id" />
              <div className="columns signup-bottom-student-action-buttons">
                <div className="column is-4 is-offset-2">
                  <div className="field control">
                    <button
                      type="button"
                      disabled={isSubmitting || values.studentAccounts.length >= 4}
                      className="button is-info is-fullwidth"
                      onClick={addAnotherStudent}
                    >
                      ADD ANOTHER STUDENT
                    </button>
                  </div>
                </div>
                <div className="column is-4 is-offset-0">
                  <div className="field control">
                    <button
                      type="button"
                      disabled={isSubmitting || values.studentAccounts.length <= 1}
                      className="button is-danger is-fullwidth"
                      onClick={removeExtraStudent}
                    >
                      REMOVE EXTRA STUDENT
                    </button>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="field control">
                    <label className="label has-text-centered has-text-weight-normal">Have you added all the student accounts you need?</label>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="button is-success is-medium is-fullwidth"
                    >
                      CREATE ALL ACCOUNTS
                    </button>
                  </div>
                </div>
              </div>
            </>
          )
        }}
      />
    )
  }
}

export default AccountSignupBottomSection;