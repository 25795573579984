import React, { Component } from 'react';
import { getIn } from 'formik';
import Input from '../form/Input';
import { LABEL_MAPPING } from './Types';
import './Signup.css';
import { setFieldLabel } from './Signup';
import { BASE_URL } from './../service/types'

class AccountSignupParentSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
    }
  }

  render() {
    const { touched, errors, status = {}, values, handleChange, handleBlur, clearStatus } = this.props;
    const passwordInputType = this.state.isPasswordVisible ? 'text' : 'password';
    const passwordInputIcon = this.state.isPasswordVisible ? 'fas fa-eye' : 'fas fa-eye-slash';
    const handlePasswordIconClick = () => {
      this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
    }

    const existingAccountUrl = `${BASE_URL}loginhome`;

    const confirmPasswordInputType = this.state.isConfirmPasswordVisible ? 'text' : 'password';
    const confirmPasswordInputIcon = this.state.isConfirmPasswordVisible ? 'fas fa-eye' : 'fas fa-eye-slash';
    const handleConfirmPasswordIconClick = () => {
      this.setState({ isConfirmPasswordVisible: !this.state.isConfirmPasswordVisible });
    }

    return (
      <div className="panel is-info is-shadowless signup-parent-panel">
      <p className="panel-heading">Parent/Guardian</p>
      <div className="columns is-desktop">
        <div className="column is-half">
          <Input
            label={setFieldLabel(`${LABEL_MAPPING['parentAccount.firstName']}`)}
            touched={getIn(touched.parentAccount, 'firstName')}
            error={getIn(errors.parentAccount, 'firstName')}
            status={getIn(status.parentAccount, 'firstName')}
            id="parentAccount.firstName"
            name="parentAccount.firstName"
            value={values.parentAccount.firstName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            clearStatus={clearStatus}
            maxLength={31}
          />
        </div>
        <div className="column is-half">
          <Input
            label={setFieldLabel(`${LABEL_MAPPING['parentAccount.lastName']}`)}
            touched={getIn(touched.parentAccount, 'lastName')}
            error={getIn(errors.parentAccount, 'lastName')}
            status={getIn(status.parentAccount, 'lastName')}
            id="parentAccount.lastName"
            name="parentAccount.lastName"
            value={values.parentAccount.lastName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            clearStatus={clearStatus}
            maxLength={31}
          />
        </div>
      </div>

      <div className="columns is-desktop">
        <div className="column is-half">
          <Input
            label={setFieldLabel(`${LABEL_MAPPING['parentAccount.emailAddress']}`)}
            touched={getIn(touched.parentAccount, 'emailAddress')}
            error={getIn(errors.parentAccount, 'emailAddress')}
            status={getIn(status.parentAccount, 'emailAddress')}
            id="parentAccount.emailAddress"
            name="parentAccount.emailAddress"
            value={values.parentAccount.emailAddress}
            handleChange={handleChange}
            handleBlur={handleBlur}
            clearStatus={clearStatus}
          />
          <Input
            label={setFieldLabel(`${LABEL_MAPPING['parentAccount.confirmEmailAddress']}`)}
            touched={getIn(touched.parentAccount, 'confirmEmailAddress')}
            error={getIn(errors.parentAccount, 'confirmEmailAddress')}
            status={getIn(status.parentAccount, 'confirmEmailAddress')}
            id="parentAccount.confirmEmailAddress"
            name="parentAccount.confirmEmailAddress"
            value={values.parentAccount.confirmEmailAddress}
            handleChange={handleChange}
            handleBlur={handleBlur}
            clearStatus={clearStatus}
          />
        </div>
        <div className="column is-half">
          <Input
            label={setFieldLabel(`${LABEL_MAPPING['parentAccount.password']}`)}
            touched={getIn(touched.parentAccount, 'password')}
            error={getIn(errors.parentAccount, 'password')}
            status={getIn(status.parentAccount, 'password')}
            id="parentAccount.password"
            name="parentAccount.password"
            value={values.parentAccount.password}
            handleChange={handleChange}
            handleBlur={handleBlur}
            clearStatus={clearStatus}
            rightIcon={passwordInputIcon}
            onRightIconClick={handlePasswordIconClick}
            type={passwordInputType}
          />
          <Input
            label={setFieldLabel(`${LABEL_MAPPING['parentAccount.confirmPassword']}`)}
            touched={getIn(touched.parentAccount, 'confirmPassword')}
            error={getIn(errors.parentAccount, 'confirmPassword')}
            status={getIn(status.parentAccount, 'confirmPassword')}
            id="parentAccount.confirmPassword"
            name="parentAccount.confirmPassword"
            value={values.parentAccount.confirmPassword}
            handleChange={handleChange}
            handleBlur={handleBlur}
            clearStatus={clearStatus}
            rightIcon={confirmPasswordInputIcon}
            onRightIconClick={handleConfirmPasswordIconClick}
            type={confirmPasswordInputType}
          />
        </div>
      </div>
      <div className="columns is-desktop">
        <div className="column is-12">
         
            <Input
              label={setFieldLabel(`${LABEL_MAPPING['parentAccount.email_weekly']}`, false)}
              id="parentAccount.email_weekly"
              name="parentAccount.email_weekly"
              defaultChecked={values.parentAccount.email_weekly}
              value={values.parentAccount.email_weekly}
              handleChange={handleChange}
              handleBlur={handleBlur}
              type="checkbox"
            />
          
        </div>
      </div>
        <div className="columns">
          <div className="column is-6 is-offset-3">
            <div className="field control">
              <a
                  className="button is-success is-medium is-fullwidth has-text-white"
                  rel="noopener noreferrer"
                  target="blank"
                  href={existingAccountUrl}
              >
                {LABEL_MAPPING['parentAccount.existingAccount']}
              </a>
            </div>
          </div>
        </div>
    </div> 
    )
  }
}

export default AccountSignupParentSection;
