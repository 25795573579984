import React, { Component } from 'react';

class StudentLimitWarning extends Component {
  
  render() {

    return (
      <div className="message is-warning is-light">
        <div className="message-body">
        Parent account is limited to having 4 student accounts. If you need additional accounts, please return to this page and use a different email address to create a second parent account once the first set of accounts are created.
        </div>
      </div>
    );
  }
}

export default StudentLimitWarning;